import 'bootstrap/dist/css/bootstrap.css';

import StudentManagement from './page/StudentManagement';
function App() {
  return (
    <div className="App">
      <StudentManagement />
    </div>
  );
}

export default App;
